<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        sm="6"
        md="4"
        class="d-flex flex-column"
      >
        <b-form-group
          v-for="(p, index) in priceData"
          :key="index"
          :label="`Prezzo ${p.name}`"
          :label-for="`product-${p.name}`"
        >
          <b-input-group append="€">
            <b-form-input
              :id="`product-${p.name}`"
              v-model="priceData[index].price"
              placeholder="Prezzo..."
              :name="`product-${p.name}`"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="4"
        class="d-flex flex-column"
      >
        <b-form-group
          v-for="(c, index) in commissionData"
          :key="index"
          :label="`Commissioni ${c.type}`"
          :label-for="`product-${c.type}`"
        >
          <b-input-group :append="c.unit">
            <b-form-input
              :id="`product-${c.type}`"
              v-model="commissionData[index].value"
              placeholder="Prezzo..."
              :name="`product-${c.type}`"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="d-flex flex-row justify-content-end"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @clck="onSubmit"
        >
          Salva
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BButton, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    priceData: { type: Array, required: true },
    commissionData: { type: Array, required: true },
  },
  methods: {
    onSubmit() {
      // console.log(this.priceData)
    },
  },
}
</script>

<style>

</style>
