<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="generalData.img"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button> -->
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
      @reset="onReset"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-checkbox
            :checked="generalData.active"
            name="check-button"
            switch
            inline
            class="mb-1"
          >
            Attivo
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="4"
          sm="2"
        >
          <b-form-group
            label="Codice"
            label-for="services-codService"
          >
            <b-form-input
              id="product-cod"
              v-model="generalData.codService"
              placeholder="Cod."
              name="codService"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="5"
        >
          <b-form-group
            label="Nome"
            label-for="services-name"
          >
            <b-form-input
              id="services-name"
              v-model="generalData.name"
              placeholder="Nome"
              name="Nome"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="5"
        >
          <b-form-group
            label="Tipo"
            label-for="services-type"
          >
            <v-select
              v-model="generalData.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="optionType"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        class=""
      >
        <b-col
          cols="12"
          md="6"
        >
          <b-row>
            <b-col
              cols="6"
              lg="3"
              class=""
            >
              <b-form-group
                label="Unità"
                label-for="services-unit"
              >
                <b-form-input
                  id="services-unit"
                  v-model="generalData.unit"
                  name="unit"
                  placeholder="kg,pz,ml"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              lg="3"
              class=""
            >
              <b-form-group
                label="Quantità"
                label-for="services-quantity"
              >
                <b-form-input
                  id="services-quantity"
                  v-model="generalData.quantity"
                  name="quantity"
                  placeholder="0.5, 1, 10, 100"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-col
            class="p-0"
          >
            <b-form-group
              class=""
              label="Descrizione"
              label-for="services-description"
            >
              <b-form-textarea
                id="services-description"
                v-model="generalData.description"
                placeholder="Descrizione..."
                rows="2"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col
            class="p-0"
          >
            <b-form-group
              label="SubCategory"
              label-for="services-sub-categories"
            >
              <v-select
                id="services-sub-categories"
                v-model="generalData.subCategories"
                :options="optionSubCat"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                placeholder="Add Options"
                label="title"
              />
            </b-form-group>
          </b-col> -->
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="p-0 d-flex flex-column"
        >
          <b-col
            class=""
          >
            <b-form-group
              label="Categorie"
              label-for="services-categories"
            >
              <v-select
                v-model="generalData.categories"
                :options="optionCat"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                placeholder="Add Options"
                label="title"
              />

            </b-form-group>

          </b-col>
          <b-col
            class=""
          >
            <b-form-group
              label="Note"
              label-for="services-note"
            >
              <b-form-textarea
                id="services-note"
                v-model="generalData.note"
                placeholder="Descrizione..."
                rows="2"
                class=""
              />
            </b-form-group>
          </b-col>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(date, index) in generalData.dateServices"
          :key="index"
          cols="12"
          sm="6"
          md="6"
          lg="3"
          class=""
        >
          <b-form-group
            :label="date.title"
            :label-for="`services-${date.title}`"
          >
            <b-form-datepicker
              :id="`services-${date.title}`"
              v-model="generalData.dateServices[index].date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              class="mb-1"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex flex-row justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            type="submit"
          >
            Salva
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormTextarea, BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionType: [
        { title: 'Servizi' },
        { title: 'Accessori' },
        { title: 'Farmaci' },
        { title: 'Parafarmaco' },
      ],
      profileFile: null,
      optionSubCat: [],
      optionCat: [],
    }
  },
  beforeMount() {
    // this.optionSubCat.push('prova')
    this.optionSubCat = [...this.generalData.subCategories]
    this.optionCat = [...this.generalData.categories]
  },
  methods: {
    onReset() {
      // console.log('resetForm')
    },
    onSubmit() {
      // console.log('changes')
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
