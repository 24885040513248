<template>
  <b-tabs
    vertical
    pills
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Generali</span>
      </template>

      <DetailsGeneral
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Prezzi</span>
      </template>

      <DetailsPrice
        :price-data="options.prices.price"
        :commission-data="options.prices.commission"
      />
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import DetailsGeneral from './DetailsGeneral.vue'
import DetailsPrice from './DetailsPrice.vue'

export default {
  components: {
    BTabs,
    BTab,
    DetailsGeneral,
    DetailsPrice,

  },
  data() {
    return {
      options: {},
    }
  },
  beforeMount() {
    this.account = {
      general: {
        // eslint-disable-next-line global-require
        avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
        username: 'johndoe',
        fullName: 'John Doe',
        email: 'granger007@hogward.com',
        company: 'Crystal Technologies',
      },
      info: {
        bio: '',
        dob: null,
        country: 'USA',
        website: '',
        phone: 6562542568,
      },
      social: {
        socialLinks: {
          twitter: 'https://www.twitter.com',
          facebook: '',
          google: '',
          linkedIn: 'https://www.linkedin.com',
          instagram: '',
          quora: '',
        },
        connections: {
          twitter: {
            // eslint-disable-next-line global-require
            profileImg: '/img/avatar-s-11.14cf1734.jpg',
            id: 'johndoe',
          },
          google: {
            // eslint-disable-next-line global-require
            profileImg: '/img/avatar-s-11.14cf1734.jpg',
            id: 'luraweber',
          },
          facebook: {},
          github: {},
        },
      },
      notification: {
        commentOnArticle: true,
        AnswerOnForm: true,
        followMe: false,
        newAnnouncements: true,
        productUpdates: true,
        blogDigest: false,
      },
    }
    this.options = {
      general: {
        // eslint-disable-next-line global-require
        img: require('@/assets/images/servicesVET/ambulanzaVet.jpg'),
        active: true,
        name: 'Pronto Soccorso a domicilio',
        codService: '0090',
        description: 'Pronto Soccordo a domicilio direttamente a casa del paziente',
        type: 'Servizio',
        categories: ['Medic', 'Pronto Soccorso', 'Servizi'],
        subCategories: [{ title: 'paziente' }, { title: 'sub-2' }, { title: 'sub-3' }],
        unit: 'cad',
        quantity: '1',
        dateServices: [
          { title: 'Inizio Vendite', date: '2021-07-15' },
          { title: 'Fine Vendite', date: '2021-10-15' },
          { title: 'Inizio Data Supporto', date: '2021-07-15' },
          { title: 'Fine Data Supporto', date: '2022-10-15' },
        ],
        owner: 'FAI.Business',
        tax: { type: 'IVA', value: '22' },
        note: '',
      },
      prices: {
        commission: [
          { type: 'Venditore', value: '10', unit: '%' },
          { type: 'Dottore', value: '25', unit: '%' },
          { type: 'Partner', value: '10', unit: '%' },
          { type: 'Esterni', value: '5', unit: '%' },
        ],
        price: [{ name: 'Privati', price: 100 }, { name: 'Aziende', price: 90 }, { name: 'Partners', price: 80 }],
      },
    }
  },
}
</script>
